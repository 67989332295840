.marketing-section {
  background-color: rgb(203, 208, 213);
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* -------------- pentagons ---------------- */
.marketing-section .dirtBlue-ofers,
.marketing-section .dirtBlue-ofers2,
.marketing-section .dirtBlue-ofers3 {
  position: absolute;
  top: 50%;
  left: -40%;
  transform: rotate(-20deg);
  transition: 0.6s;
}
.marketing-section .dirtBlue-ofers2 {
  top: -40%;
  left: 25%;
  transform: rotate(180deg);
}
.marketing-section .dirtBlue-ofers3 {
  top: 45%;
  left: 140%;
  transform: rotate(-10deg);
}
.marketing-section .dirtBlue-ofers:before,
.marketing-section .dirtBlue-ofers2:before,
.marketing-section .dirtBlue-ofers3:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 160px solid rgba(60, 74, 110, 0.8);
  border-left: 160px solid transparent;
  border-right: 160px solid transparent;
  bottom: 160px;
  left: -80px;
}
/* ------ */
.marketing-section .dirtBlue-ofers.active,
.marketing-section .dirtBlue-ofers2.active,
.marketing-section .dirtBlue-ofers3.active {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: rotate(-20deg);
}
.marketing-section .dirtBlue-ofers2.active {
  top: 50%;
  left: 25%;
  transform: rotate(180deg);
}
.marketing-section .dirtBlue-ofers3.active {
  top: 45%;
  left: 35%;
  transform: rotate(-10deg);
}
.marketing-section .dirtBlue-ofers.active:before,
.marketing-section .dirtBlue-ofers2.active:before,
.marketing-section .dirtBlue-ofers3.active:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 160px solid rgba(60, 74, 110, 0.8);
  border-left: 160px solid transparent;
  border-right: 160px solid transparent;
  bottom: 160px;
  left: -80px;
}
/* -------------- text--------------------- */
.offers-title-marketing {
  width: 80%;
  font-family: var(--headersFont);
  text-align: center;
  font-size: 5rem;
  font-weight: 400;
  z-index: 2;
}
.offers-text-marketing {
  width: 50%;
  color: white;
  text-align: center;
  z-index: 2;
  font-size: 1.4rem;
  padding: 10%;
  line-height: 40px;
}
@media (orientation: landscape) and (max-width: 823px) {
  .offers-title-marketing {
    width: 80%;
    padding-top: 0;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .offers-text-marketing {
    width: 80%;
    font-size: 1rem;
    padding: 0 5%;
    line-height: 1.2rem;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  .offers-title-marketing {
    width: 90%;
    padding-top: 20%;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .offers-text-marketing {
    width: 90%;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
