header {
  width: 100%;
  height: 10vh;
  transition: 0.3s;
  background-color: #fff;
  position: relative;
}
.header-wrapper {
  width: 95vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.header-wrapper.fixed {
  width: 100%;
  height: 10vh;
  position: fixed;
  top: -10vh;
  left: 0;
  z-index: 3;
  transition: 0.3s;
}
.header-wrapper.fixed.active {
  width: 100%;
  height: 10vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: 0.3s;
}
@media (max-width: 823px) and (orientation: landscape) {
  header {
    height: 15vh;
  }
  .header-wrapper {
    height: 15vh;
  }
  .header-wrapper.fixed.active {
    height: 15vh;
  }
}
