.footer-wrapper {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: rgb(203, 208, 213);
  overflow: hidden;
}
.footer-wrapper .logo-wrapper,
.footer-wrapper .social-wrapper,
.footer-wrapper .terms-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 33%;
  height: 100%;
  color: rgb(203, 208, 213);
  padding: 10%;
}
.footer-wrapper .logo-wrapper {
  position: relative;
}
.footer-wrapper .social-wrapper,
.footer-wrapper .terms-wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer-wrapper .social-wrapper a {
  width: 100%;
}
.footer-wrapper .logo-wrapper a,
.footer-wrapper .social-wrapper a,
.footer-wrapper .terms-wrapper a {
  display: block;
  flex-basis: 10%;
  padding: 20%;
  text-decoration: none;
  color: rgb(203, 208, 213);
  text-transform: uppercase;
  font-size: 1.2rem;
  cursor: pointer;
  border-bottom: 1px solid black;
  transition: 0.3s;
  position: relative;
}

.footer-wrapper .logo-wrapper a:hover,
.footer-wrapper .social-wrapper a:hover,
.footer-wrapper .terms-wrapper a:hover {
  border-bottom: 1px solid rgb(203, 208, 213);
}
.footer-wrapper .logo-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.footer-wrapper .logo-wrapper span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 25%;
}
.fab {
  font-size: 2rem;
  padding-right: 10px;
}
.fab.fa-facebook-f {
  vertical-align: -5px;
}
.fab.fa-instagram {
  vertical-align: -5px;
}

@media (max-width: 860px) {
  .footer-wrapper {
    flex-direction: column;
    height: 90vh;
  }
  .footer-wrapper .logo-wrapper {
    width: 100%;
    margin: 0;
    order: 1;
  }
  .footer-wrapper .logo-wrapper,
  .footer-wrapper .social-wrapper,
  .footer-wrapper .terms-wrapper {
    padding: 0;
  }

  .footer-wrapper .social-wrapper a,
  .footer-wrapper .terms-wrapper a {
    flex-basis: 10%;
    padding: 10%;
  }
  .footer-wrapper .logo-wrapper img {
  width: 90%;
  }
}
@media (orientation: landscape) and (max-width: 823px) {
  .footer-wrapper {
    height: 90vh;
    flex-direction: row;
  }
  .footer-wrapper .logo-wrapper {
    order: 0;
  }
}
