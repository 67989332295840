section.error-wrapper {
  height: 70vh;
  text-align: center;
}
section.walking {
  position: absolute;
  top: 30vh;
  left: 0;
  background-image: url("../images/walking.png");
  width: 256px;
  height: 314px;
  /* transform: scale(0.5); */
  animation: walk 1s steps(8) infinite, move 4s linear infinite alternate,
    switch 8s linear infinite, sizer 4s linear infinite;
}

@media (max-width: 823px) and (orientation: landscape) {
  section.error-wrapper {
    height: 90vh;
  }
}
@keyframes walk {
  100% {
    background-position: -2048px;
  }
}

@keyframes move {
  100% {
    left: calc(100% - 256px);
  }
}

@keyframes switch {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(0);
  }

  50.01% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}
