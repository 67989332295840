.responsive-website {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}
.responsive-website .h1wrapper {
  width: 80%;
  height: 100%;
}

.responsive-website .h1wrapper h1.offers {
  position: absolute;
  width: 100%;
  bottom: 5%;
  left: -100%;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
  color: rgb(60, 74, 86);
  transform: translate(-50%, -50%);
  animation: responsiveH1 5s 1 both;
}
.responsive-website .h1wrapper p.offersAlt {
  position: absolute;
  width: 100%;
  bottom: 1%;
  left: 150%;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
  color: rgb(45, 46, 45);
  transform: translate(-50%, -50%);
  animation: responsiveP 5s 1 both;
}
@media (max-width: 1023px) {
  .responsive-website .h1wrapper h1.offers {
    font-size: 2rem;
  }
  .responsive-website .h1wrapper p.offersAlt {
    font-size: 1.5rem;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  .responsive-website .h1wrapper h1.offers {
    bottom: 10%;
  }
}

@keyframes responsiveH1 {
  0% {
    left: 150%;
  }
  45% {
    left: 45%;
  }
  55% {
    left: 55%;
  }
  100% {
    left: 50%;
  }
}

@keyframes responsiveP {
  0% {
    left: -100%;
  }
  45% {
    left: 55%;
  }
  55% {
    left: 45%;
  }
  100% {
    left: 50%;
  }
}
