nav.main-nav {
  flex-basis: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

nav.main-nav ul {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
nav.main-nav ul a {
  display: block;
  flex-basis: 300px;
  margin: 10px;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;

  text-decoration: none;
  cursor: pointer;
  color: rgb(45, 46, 45);
  border-radius: 25px;
  transition: 1s;
  box-shadow: none;
}

nav.main-nav ul a.active {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

nav.main-nav ul a:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
/* -----------nav-burger------------------ */
nav.burger-nav {
  display: none;
}
@media (max-width: 1024px) {
  nav.main-nav {
    display: none;
  }
  nav.burger-nav {
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    transition: 0.5s;
    z-index: 998;
  }
  nav.burger-nav ul {
    display: flex;
    width: 100%;
    height: 80vh;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10vh;
    box-sizing: border-box;
    transition: 0.5s;
  }
  nav.burger-nav ul a {
    width: 60%;
    line-height: 20vh;
    border-bottom: 1px solid rgb(45, 46, 45);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: rgb(45, 46, 45);
    cursor: pointer;
  }
  /* --------------burger- menu- active ----------*/
  nav.burger-nav.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(203, 208, 213);
  }
  nav.burger-nav.active ul {
    width: 100%;
    margin-top: 10vh;
    height: 80vh;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    z-index: 2;
  }

  nav.burger-nav.active ul a:hover {
    background-color: #fff;
  }
  /* ----------------------burger-icon -----------------*/
  nav.burger-nav .burger {
    position: fixed;
    top: 0;
    right: 10px;
    width: 70px;
    height: 70px;
    cursor: pointer;
    transition: 0.3s;
    z-index: 3;
  }

  .first-span,
  .second-span,
  .third-span {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 4px;
    border-radius: 3px;
    background-color: rgb(45, 46, 45);

    transform-origin: right;
    transform: rotate(0);
    transition: 0.3s;
  }
  .second-span {
    top: 30px;
    right: 15px;
  }
  .third-span {
    top: 40px;
  }
  nav.burger-nav .burger:hover .first-span,
  nav.burger-nav .burger:hover .third-span {
    width: 25px;
  }
  nav.burger-nav .burger:hover .second-span {
    width: 25px;
    right: 20px;
  }

  nav.burger-nav .burger.active .first-span {
    width: 28.25px;
    transform: rotate(-45deg);
  }
  nav.burger-nav .burger.active .second-span {
    width: 0;
  }
  nav.burger-nav .burger.active .third-span {
    width: 28.25px;
    transform: rotate(45deg);
  }
}
@media (max-width: 667px) and (orientation: landscape) {
  nav.burger-nav .burger {
    top: -4px;
  }
}
