.analytics-section {
  background-color: rgba(60, 74, 110, 0.8);
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* ----------- pentagons---------------- */
.analytics-section .darkGrey-ofers,
.analytics-section .darkGrey-ofers2,
.analytics-section .darkGrey-ofers3 {
  position: absolute;
  top: -40%;
  left: 63%;
  transform: rotate(40deg);
  transition: 0.6s;
}
.analytics-section .darkGrey-ofers2 {
  top: 55%;
  left: -40%;
  transform: rotate(170deg);
}
.analytics-section .darkGrey-ofers3 {
  top: 51%;
  left: 140%;
  transform: rotate(18deg);
}
.analytics-section .darkGrey-ofers:before,
.analytics-section .darkGrey-ofers2:before,
.analytics-section .darkGrey-ofers3:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 160px solid rgba(45, 46, 45, 0.8);
  border-left: 160px solid transparent;
  border-right: 160px solid transparent;
  bottom: 160px;
  left: -80px;
}
/* --------- */
.analytics-section .darkGrey-ofers.active,
.analytics-section .darkGrey-ofers2.active,
.analytics-section .darkGrey-ofers3.active {
  position: absolute;
  top: 70%;
  left: 63%;
  transform: rotate(40deg);
}
.analytics-section .darkGrey-ofers2.active {
  top: 55%;
  left: 45%;
  transform: rotate(170deg);
}
.analytics-section .darkGrey-ofers3.active {
  top: 51%;
  left: 55%;
  transform: rotate(18deg);
}
.analytics-section .darkGrey-ofers.active:before,
.analytics-section .darkGrey-ofers2.active:before,
.analytics-section .darkGrey-ofers3.active:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 160px solid rgba(45, 46, 45, 0.8);
  border-left: 160px solid transparent;
  border-right: 160px solid transparent;
  bottom: 160px;
  left: -80px;
}
/* -------------- text------------------- */
.offers-title-analytics {
  width: 80%;
  color: white;
  font-family: var(--headersFont);
  text-align: center;
  font-size: 5rem;
  font-weight: 400;
  z-index: 2;
}
.offers-text-analytics {
  width: 50%;
  color: white;
  text-align: center;
  z-index: 2;
  font-size: 1.4rem;
  padding: 10%;
  line-height: 40px;
}
@media (orientation: landscape) and (max-width: 823px) {
  .offers-title-analytics {
    width: 80%;
    padding-top: 0;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .offers-text-analytics {
    width: 80%;
    font-size: 1rem;
    padding: 0 5%;
    line-height: 1.2rem;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  .offers-title-analytics {
    width: 90%;
    padding-top: 20%;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .offers-text-analytics {
    width: 80%;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
