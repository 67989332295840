.main-page-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
  background-position: center;
  background-size: cover;
}
.main-page-slider.animationActive {
  animation: imageSlider infinite 5s;
}
.content-slider-wraper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.content-slider-wraper h1 {
  color: white;
  font-size: 6rem;
  text-transform: uppercase;
  text-align: center;
  text-shadow: rgb(45, 46, 45) 2px 2px 10px;
  animation: textSlider infinite 5s;
}
.content-slider-wraper p {
  color: black;
  font-size: 4rem;
  font-weight: 200;
  text-align: center;
  /* text-shadow: white 2px 2px 5px; */
  animation: textSlider infinite 5s;
}
@media (max-width: 1023px) {
  .content-slider-wraper h1 {
    font-size: 2.8rem;
  }
  .content-slider-wraper p {
    font-size: 2rem;
  }
}
@media (max-width: 824px) and (orientation: landscape) {
  .content-slider-wraper {
    top: 60%;
  }
  .content-slider-wraper h1 {
    font-size: 2.2rem;
  }
  .content-slider-wraper p {
    font-size: 1.8rem;
  }
}
@keyframes imageSlider {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(1.02);
  }
}
@keyframes textSlider {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  10% {
    opacity: 0;
    transform: scale(1);
  }

  90% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
