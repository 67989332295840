.logo-wrapper {
  height: 80%;
  flex-basis: 30%;
  margin-left: 5%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-wrapper img {
  display: block;
}
@media (max-width: 1024px) {
  .logo-wrapper {
    height: 80%;
    flex-basis: 50%;
    margin-left: 10px;
  }
  /* .logo-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
     transform: translate(0, -50%);
     z-index: 999; 
  } */
}
@media (max-width: 823px) and (orientation: landscape) {
  .logo-wrapper img {
    max-width: 80%;
    max-height: 80%;
  }
}

@media (max-width: 540px) {
  .logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
  }
}
