.responsive-section {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* -------------------- pentagons styles------------------ */
.responsive-section .green-ofers,
.responsive-section .green-ofers2,
.responsive-section .green-ofers3 {
  position: absolute;
  top: 140%;
  left: 45%;
  transform: rotate(-20deg);
  transition: 0.6s;
}
.responsive-section .green-ofers2 {
  top: 100%;
  left: -40%;
  transform: rotate(10deg);
}
.responsive-section .green-ofers3 {
  top: 45%;
  left: 140%;
  transform: rotate(-10deg);
}
.responsive-section .green-ofers:before,
.responsive-section .green-ofers2:before,
.responsive-section .green-ofers3:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 160px solid rgba(297, 135, 60, 0.8);
  border-left: 160px solid transparent;
  border-right: 160px solid transparent;
  bottom: 160px;
  left: -80px;
}
/* ----- */

.responsive-section .green-ofers.active,
.responsive-section .green-ofers2.active,
.responsive-section .green-ofers3.active {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: rotate(-20deg);
}
.responsive-section .green-ofers2.active {
  top: 68%;
  left: 35%;
  transform: rotate(10deg);
}
.responsive-section .green-ofers3.active {
  top: 45%;
  left: 55%;
  transform: rotate(-10deg);
}
.responsive-section .green-ofers.active:before,
.responsive-section .green-ofers2.active:before,
.responsive-section .green-ofers3.active:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 160px solid rgba(297, 135, 60, 0.8);
  border-left: 160px solid transparent;
  border-right: 160px solid transparent;
  bottom: 160px;
  left: -80px;
}
/* ---------------------- text ----------------- */
.offers-title {
  width: 80%;
  font-family: var(--headersFont);
  text-align: center;
  font-size: 5rem;
  font-weight: 400;
  z-index: 2;
}
.offers-text {
  width: 50%;
  text-align: center;
  z-index: 2;
  font-size: 1.4rem;
  padding: 10%;
  line-height: 40px;
}
@media (orientation: landscape) and (max-width: 823px) {
  .offers-title {
    width: 80%;
    padding-top: 10%;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .offers-text {
    width: 80%;
    font-size: 1rem;
    padding: 0 5%;
    line-height: 1.2rem;
  }
}
@media (orientation: portrait) and (max-width: 414px) {
  .offers-title {
    width: 90%;
    padding-top: 20%;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .offers-text {
    width: 80%;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
